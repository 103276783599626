body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#main-nav {
  background: #fff !important;
  border-bottom: 1px solid #e8e8e8;
}

#main-nav .btn-light {
  background: #fff !important;
  border: 1px solid #e8e8e8;
  outline: none;
  color: #8d99ae;
}

#main-nav .btn-light:hover {
  color: #2196f3;
}

#main-nav .btn-light:focus {
  background: #f8f8f8 !important;
  color: #2196f3;
  box-shadow: none;
}
